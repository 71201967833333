<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddModuleAccess
      :current_view="current_view"
      :StoreObj="StoreObj"
      :RootModuleObj="RootModuleObj"
      :addModuleAccessDialog="addModuleAccessDialog"
      @clicked="addModuleAccessDialogEmit"
    />
    <DeleteModule
      :dialogDeleteModule="dialogDeleteModule"
      :StoreObj="StoreObj"
      @clicked="dialogDeleteModuleEmit"
    />
    <v-card class="ma-1" flat elevation="0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">
              {{
                current_view == "LIST_MODULES" ? "Module Access" : "Sub Modules"
              }}
            </div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
              dense
              outlined
              class="FontSize mt-9 field_label_size field_height mr-2"
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  @click="
                    (addModuleAccessDialog = true), (StoreObj.action = 'CREATE')
                  "
                  v-on="on"
                  v-bind="attrs"
                  class="ml-2 mt-3 primary white--text text-capitalize"
                  ><v-icon class="mr-2" small>mdi-plus</v-icon>
                  {{
                    current_view == "LIST_MODULES"
                      ? "Create Module"
                      : "Create Sub Module"
                  }}</v-btn
                >
              </template>
              <span class="white--text">{{
                this.current_view == "LIST_MODULES"
                  ? "Create Module"
                  : "Create Sub Module"
              }}</span>
            </v-tooltip>
            <v-btn
              depressed
              v-if="current_view != 'LIST_MODULES'"
              small
              color="primary"
              @click="current_view = 'LIST_MODULES'"
              dark
              class="ml-2 mt-3 white--text"
              ><v-icon>mdi-chevron-double-left</v-icon>Back</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :no-data-text="noDataText"
          :items="GetAllModuleList"
          @click:row="CheckRowItem"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
        >
          <template v-slot:[`item.organization_name`]="{ item }">
            <div class="FontSize">{{ item.organization_name }}</div>
          </template>
          <template v-slot:[`item.country_name`]="{ item }">
            <div class="FontSize">{{ item.country_name }}</div>
          </template>
          <template v-slot:[`item.organization_ticket_prefix`]="{ item }">
            <div class="FontSize">{{ item.organization_ticket_prefix }}</div>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click.stop="checkItem(item, 'EDIT')"
                  color="primary"
                  small
                  v-on="on"
                  class="mr-2"
                >
                  mdi-pencil-plus
                </v-icon>
              </template>
              <span class="white--text">Update Module</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click.stop="checkItem(item, 'DELETE')"
                  color="red"
                  small
                  v-on="on"
                  class="red--text"
                >
                  {{ "mdi-delete" }}
                </v-icon>
              </template>
              <span class="white--text">
                {{ "De-Activate this Module ?" }}</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllModules } from "@/mixins/GetAllModuleAccess.js";
import Overlay from "@/components/Extras/Overlay.vue";
import DeleteModule from "@/components/ModuleAccess/Dialogs/DeleteModule.vue";
import AddModuleAccess from "@/components/ModuleAccess/Dialogs/AddModuleAccess.vue";
export default {
  components: {
    Overlay,
    DeleteModule,
    AddModuleAccess,
  },
  mixins: [GetAllModules],
  data: () => ({
    TableHeight :0,
    overlay: false,
    dialogDeleteModule: false,
    addModuleAccessDialog: false,
    search: "",
    current_view: "LIST_MODULES",
    GetAllModulesList: [],
    headers: [
      { text: "Module Name", value: "module_name" },
      { text: "Actions", value: "Actions" },
    ],
    StoreObj: {},
    RootModuleObj: {},
  }),
  watch: {
    current_view(val) {
      if (val === "LIST_MODULES") {
        this.GetAllModuleAccessMethod();
      }
    },
  },
  mounted() {
    this.GetAllModuleAccessMethod();
    this.TableHeight = window.innerHeight - 200;
  },
  methods: {
    dialogDeleteModuleEmit(Toggle) {
      this.dialogDeleteModule = false;
      if (Toggle == 2) {
        if (this.current_view == "LIST_MODULES") {
          this.GetAllModuleAccessMethod();
        } else {
          this.GetAllModuleAccessMethod(this.RootModuleObj.module_id);
        }
      }
    },
    CheckRowItem(item) {
      this.current_view = "LIST_SUB_MODULES";
      this.RootModuleObj = item;
      this.GetAllModuleAccessMethod(item.module_id);
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "EDIT") {
        this.addModuleAccessDialog = true;
      } else if (action == "DELETE") {
        this.dialogDeleteModule = true;
      }
    },
    addModuleAccessDialogEmit(Toggle) {
      this.addModuleAccessDialog = false;
      if (Toggle == 2) {
        if (this.current_view == "LIST_MODULES") {
          this.GetAllModuleAccessMethod();
        } else {
          this.GetAllModuleAccessMethod(this.RootModuleObj.module_id);
        }
      }
    },
  },
};
</script>
