<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="400px" v-model="addModuleAccessDialog">
      <v-toolbar class="primary elevation-0" dense dark>
        <v-toolbar-title class="ml-2 white--text"
          >{{ StoreObj.action == "CREATE" ? "Create" : "Edit" }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          large
          class="mr-n4"
          dark
          text
          @click="addModuleAccessDialogEmit((Toggle = 1))"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-card-text class="mt-4 pb-0">
          <v-form ref="form">
            <v-text-field
              outlined
              dense
              label="Module Name *"
              class="FontSize field_label_size field_height"
              :rules="[(v) => !!v || 'Required']"
              v-model="module_name"
            ></v-text-field>
            <b>Module Rights</b>
            <v-row>
              <v-col
                cols="12"
                md="3"
                sm="12"
                xs="12"
                v-for="(accessName, idx) in ModuleAccess"
                :key="idx"
              >
                <v-checkbox :label="accessName.text" v-model="accessName.value">
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            @click="validateMethod()"
            class="mr-2 secondary white--text"
            ><span v-text="StoreObj.action == 'CREATE' ? 'ADD' : 'EDIT'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API, graphqlOperation } from "aws-amplify";
import { CreateModule, UpdateModule } from "@/graphql/mutations.js";
import CountryList from "@/mixins/CountryList.json";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  props: {
    StoreObj: Object,
    current_view: String,
    RootModuleObj: Object,
    addModuleAccessDialog: Boolean,
  },
  data: () => ({
    Toggle: 0,
    module_name: "",
    module_description: "",
    SnackBarComponent: {},
    isLoading: false,
    edit_module: false,
    view_module: false,
    create_module: false,
    delete_module: false,
    CountryList: [],
    ModuleAccess: [
      {
        text: "CREATE",
        value: false,
      },
      {
        text: "EDIT",
        value: false,
      },
      {
        text: "DELETE",
        value: false,
      },
      {
        text: "VIEW",
        value: false,
      },
    ],
  }),
  watch: {
    addModuleAccessDialog(val) {
      if (val == true) {
        console.log("StoreObj", this.StoreObj);
        if (this.StoreObj.action == "EDIT") {
          this.module_name = this.StoreObj.module_name;
          this.module_description = this.StoreObj.module_description;
          this.StoreObj.module_rights.map((item) => {
            Object.entries(item).map((moduleaccess) => {
              this.ModuleAccess.map((internal) => {
                if (internal.text === moduleaccess[0]) {
                  internal.text = moduleaccess[0];
                  internal.value = moduleaccess[1];
                }
                this.$forceUpdate();
              });
            });
          });
        } else {
          this.$refs.form.reset();
        }
      }
    },
  },
  mounted() {
    this.CountryList = CountryList;
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.CreateEditMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all the required details",
        };
      }
    },
    async CreateEditMethod() {
      let localArray = [];
      let localObj = {};
      this.ModuleAccess.forEach((checkedvalue) => {
        localObj[checkedvalue.text] =
          checkedvalue.value == null ? false : checkedvalue.value;
      });
      localArray.push(localObj);
      var MutationName =
        this.StoreObj.action == "CREATE" ? CreateModule : UpdateModule;
      var MutationResult =
        this.StoreObj.action == "CREATE" ? "CreateModule" : "UpdateModule";
      switch (this.StoreObj.action) {
        case "CREATE":
          var inputParams = {
            user_email_id:
              this.$store.getters.get_current_user_details.user_email_id,
            module_name: this.module_name,
            module_description: this.module_description,
            module_rights: localArray,
            module_id:
              this.current_view === "LIST_SUB_MODULES"
                ? this.RootModuleObj.module_id
                : undefined,
          };
          break;
        case "EDIT":
          inputParams = {
            user_email_id:
              this.$store.getters.get_current_user_details.user_email_id,
            module_name: this.module_name,
            module_description: this.module_description,
            module_rights: localArray,
            module_id: this.StoreObj.module_id,
            module_type: this.StoreObj.module_type,
          };
          break;
      }
      try {
        let result = await API.graphql(
          graphqlOperation(MutationName, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[MutationResult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.addModuleAccessDialogEmit(2);
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async UpdateModule() {
      this.isLoading = true;
      try {
        var inputParams = {
          user_email_id:
            this.$store.getters.get_current_user_details.user_email_id,
          module_name: this.module_name,
          module_description: this.module_description,
          module_id: this.StoreObj.module_id,
          module_rights: [
            {
              CREATE: this.create_module == true ? true : false,
              VIEW: this.view_module == true ? true : false,
              EDIT: this.edit_module == true ? true : false,
              DELETE: this.delete_module == true ? true : false,
            },
          ],
        };
        let result = await API.graphql(
          graphqlOperation(UpdateModule, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateModule);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.addModuleAccessDialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async addModuleAccess() {
      this.isLoading = true;
      try {
        var inputParams = {
          user_email_id:
            this.$store.getters.get_current_user_details.user_email_id,
          module_name: this.module_name,
          module_description: this.module_description,
          module_rights: [
            {
              CREATE: this.create_module == true ? true : false,
              VIEW: this.view_module == true ? true : false,
              EDIT: this.edit_module == true ? true : false,
              DELETE: this.delete_module == true ? true : false,
            },
          ],
        };
        let result = await API.graphql(
          graphqlOperation(CreateModule, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateModule);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.addModuleAccessDialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    addModuleAccessDialogEmit(Toggle) {
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
