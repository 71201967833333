import { ListModule } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllModules = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllModuleList: [],
    };
  },
  methods: {
    async GetAllModuleAccessMethod(module_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListModule, {
            input: {
              user_email_id:
                this.$store.getters.get_current_user_details.user_email_id,
              module_id:
                this.current_view == "LIST_SUB_MODULES" ? module_id : undefined,
            },
          })
        );
        this.GetAllModuleList = JSON.parse(
          result.data.ListModule
        ).Status_Message;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllModuleList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
